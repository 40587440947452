import { Injectable } from '@angular/core';
import { Offer, OfferDetails } from '../models/offer.interface';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { OfferTypeType, OrderByType } from '../models/types';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  constructor(private http: HttpClient) {}

  getOneOffer(id: number): Observable<OfferDetails> {
    return this.http.get<OfferDetails>(`offers/${id}/`);
  }

  getLandingPageOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>('offers/landing_page/');
  }

  getCarouselOffers(): Observable<Offer[]> {
    return this.http.get<Offer[]>('offers/promoted/');
  }

  getOtherOffersExcludingOne(id: number): Observable<Offer[]> {
    const params = new HttpParams().set('exclude_id', id);
    return this.http.get<Offer[]>('offers/random/', { params });
  }

  getOffers(offerType: OfferTypeType | null, orderBy?: OrderByType): Observable<Offer[]> {
    let params = new HttpParams();
    if (offerType) params = params.set('offer_type', offerType);
    if (orderBy) params = params.set('order_by', orderBy);

    return this.http.get<Offer[]>('offers/', { params });
  }
}
